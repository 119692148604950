import React, { useState, useEffect, useRef } from "react";
import {
    EventApi,
    EventInput,
    DateSelectArg,
    EventClickArg,
    EventContentArg,
    formatDate,
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import AbunConfirmationModal from '../../components/AbunConfirmationModal/AbunConfirmationModal';
import AbunModal from '../../components/AbunModal/AbunModal';
import AbunButton from "../../components/AbunButton/AbunButton";
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    getScheduledArticles,
    scheduleArticleMutation,
    retryFn,
} from "../../utils/api";
import BasicTimePicker from "../../components/DateTimePicker/TimePicker";
import dayjs, { Dayjs } from 'dayjs';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

type UnScheduledArticle = {
    article_uid: string;
    article_title: string;
};

const DemoApp: React.FC = () => {
    // ---------------------------- Constants ----------------------------
    const dateTimeFormat = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'Asia/Kolkata',
        timeZoneName: 'short',
    });


    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [eventTitle, setEventTitle] = useState('');
    const [selectInfo, setSelectInfo] = useState<DateSelectArg | null>(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const [confirmCallback, setConfirmCallback] = useState<((confirmed: boolean) => void) | null>(null);
    const [articlesData, setArticlesData] = useState<Array<UnScheduledArticle>>([]);
    const [schedulePublishTime, setSchedulePublishTime] = React.useState<Dayjs | null>(dayjs().add(1, 'hour'));
    const [selectedArticleUID, setSelectedArticleUID] = useState<string>('');
    const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState<boolean>(false);
    const [scheduledArticles, setScheduledArticles] = useState<EventApi[]>([]);
    const [scheduledArticleEvents, setScheduledArticleEvents] = useState<EventInput[]>([]);

    // ---------------------------- QUERIES ----------------------------
    const { data, isFetching, error, refetch } = useQuery({
        queryKey: ['getCompetitorDomain'],
        queryFn: getScheduledArticles,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        retry: retryFn,
    });

    // ----------------------- REFS -----------------------
    const successAlertRef = useRef<any>(null);
    const errorAlertRef = useRef<any>(null);

    // ----------------------- MUTATIONS -----------------------
    const scheduleArticle = useMutation(scheduleArticleMutation);


    // ------------------------------ Effects ------------------------------
    useEffect(() => {
        if (data && data['data']) {
            if (data['data']['scheduled_articles']) {
                console.log("Scheduled Articles: ", data['data']['scheduled_articles']);
                setScheduledArticles(data['data']['scheduled_articles'].map((article: Object) => {
                    const formattedStr = article['schedule_datetime'].replace(" IST", "").replace(", ", " ");
                    const parsedDate = new Date(formattedStr);
                    return {
                        id: article['article_uid'],
                        title: article['title'],
                        start: parsedDate,
                    }
                }));
                setScheduledArticleEvents(data['data']['scheduled_articles'].map((article: Object) => {
                    const formattedStr = article['schedule_datetime'].replace(" IST", "").replace(", ", " ");
                    const parsedDate = new Date(formattedStr);
                    return {
                        id: article['article_uid'],
                        title: article['title'],
                        start: parsedDate,
                    }
                }));
            }
            if (data['data']['non_scheduled_articles']) {
                console.log("Non Scheduled Articles: ", data['data']['non_scheduled_articles']);
                setArticlesData(data['data']['non_scheduled_articles'].filter((article: UnScheduledArticle) => {
                    return article.article_title !== null;
                }));
            }
        }
    }, [data, scheduledArticles]);

    // ------------------------------ Functions ------------------------------

    const handleDateSelect = (selectInfo: DateSelectArg) => {
        setSelectInfo(selectInfo);
        setModalIsOpen(true);
    };

    const handleEventClick = (clickInfo: EventClickArg) => {
        showConfirmationModal(`Are you sure you want to delete the event '${clickInfo.event.title}'`)
            .then((confirmed) => {
                if (confirmed) {
                    clickInfo.event.remove();
                }
            });
    };

    const showConfirmationModal = (message: string): Promise<boolean> => {
        return new Promise((resolve) => {
            setConfirmMessage(message);
            setConfirmCallback(() => resolve);
            setConfirmModalOpen(true);
        });
    };

    const handleConfirm = (confirmed: boolean) => {
        if (confirmCallback) {
            confirmCallback(confirmed);
        }
        setConfirmModalOpen(false);
        setConfirmMessage('');
        setConfirmCallback(null);
    };

    const handleEvents = (events: EventApi[]) => {
        setScheduledArticles(events);
    };

    return (
        <div className="card abun-modal-card" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div className='demo-app card-content' style={{ display: 'flex', flexDirection: 'column', width: '90%', height: '90%' }}>
                {/* Sidebar */}
                <div className='demo-app-sidebar' style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                    <div className='demo-app-sidebar-section'>
                        <h2>All Scheduled Articles ({scheduledArticles.length})</h2>
                        <ul>
                            {scheduledArticles.map(renderSidebarEvent)}
                        </ul>
                    </div>
                </div>
                {/* Main Calendar */}
                <div className='demo-app-main'>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: 'today prevYear,prev,next,nextYear',
                            center: 'title',
                            right: 'timeGridWorkWeek dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        customButtons={{
                            timeGridWorkWeek: {
                                text: 'Toggle work week',
                                click: () => {
                                    setWeekendsVisible(!weekendsVisible);
                                }
                            },
                        }}
                        initialView='dayGridMonth'
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={weekendsVisible}
                        events={scheduledArticleEvents}
                        select={handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        eventClick={handleEventClick}
                        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                    />
                </div>
                <AbunConfirmationModal
                    active={confirmModalOpen}
                    headerText="Confirmation"
                    closeable={true}
                    onCancel={() => handleConfirm(false)}
                    onConfirm={() => handleConfirm(true)}
                >
                    <p>{confirmMessage}</p>
                </AbunConfirmationModal>
                <AbunModal
                    active={modalIsOpen}
                    headerText="Create auto publish event for an article"
                    closeable={true}
                    hideModal={() => setModalIsOpen(false)}
                >
                    <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Select label={"Select an article"}
                            labelId="select-article-label"
                            id="select-article"
                            defaultValue="Select an article"
                            value={eventTitle || 'Select an article'}
                            required
                            onChange={(e) => {
                                setEventTitle(e.target.value)
                                setSelectedArticleUID(e.target.value ? articlesData.find(article => article.article_title === e.target.value)?.article_uid || '' : '');
                                setIsDateTimePickerOpen(true);
                            }}
                        >
                            <MenuItem value="Select an article">Select an article</MenuItem>
                            {articlesData.map(article => (
                                <MenuItem key={article.article_uid} value={article.article_title}>{article.article_title}</MenuItem>
                            ))}
                        </Select>

                        {/* Choose date and time */}
                        <div style={{ width: "50%" }}>
                            <BasicTimePicker
                                className="mb-4"
                                label={"Schedule Article"}
                                defaultDate={schedulePublishTime ?? dayjs()} // Provide a default value when schedulePublishTime is null
                                isOpen={isDateTimePickerOpen}
                                onChange={(scheduleDate: Dayjs | null) => setSchedulePublishTime(scheduleDate)}
                                onClose={() => {
                                    setIsDateTimePickerOpen(false);
                                    successAlertRef.current?.close();
                                    errorAlertRef.current?.close();
                                }}
                            />
                        </div>

                        <AbunButton type="primary"
                            disabled={eventTitle === 'Select an article' || !schedulePublishTime || !selectedArticleUID}
                            clickHandler={() => {
                                if (selectInfo && selectedArticleUID) {
                                    selectInfo.view.calendar.addEvent({
                                        id: selectedArticleUID,
                                        title: eventTitle,
                                        start: schedulePublishTime?.format() || selectInfo.startStr,
                                        end: selectInfo.endStr,
                                        allDay: selectInfo.allDay
                                    });
                                    scheduleArticle.mutate({ articleUID: selectedArticleUID, articleScheduleDate: schedulePublishTime }, {
                                        onSuccess: (data) => {
                                            let responseData = (data as any)["data"];
                                            if (responseData["success"]) {
                                                successAlertRef.current?.show("Article scheduled successfully!");
                                            } else {
                                                errorAlertRef.current?.show(responseData["message"]);
                                            }
                                        },
                                        onError: (error) => {
                                            console.error(error);
                                            errorAlertRef.current?.show("Oops! Something went wrong. Please try again.");
                                        },
                                    });
                                }
                                setModalIsOpen(false);
                                setEventTitle('');
                                setSelectInfo(null);
                            }}>Create</AbunButton>
                    </div>
                </AbunModal>
            </div>
        </div>
    );
};

const renderEventContent = (eventContent: EventContentArg) => (
    <>
        <b>{eventContent.timeText}</b>
        <i>{eventContent.event.title}</i>
    </>
);

const renderSidebarEvent = (event: EventApi) => (
    <li key={event.id} style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <b>{formatDate(event.start!, { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' })}</b>
        <i>{event.title}</i>
    </li>
);

export default DemoApp;
