import { useRouteError } from "react-router-dom";
import { useEffect } from "react";
import NewAuthPageNavbar from "../components/NewAuthPageNavbar/NewAuthPageNavbar";
import { Link } from "react-router-dom";
import { pageURL } from "./routes";

import "./errorPage.scss";

interface ReactRouterError {
  message: string
}

export default function ErrorPage() {
  const error: ReactRouterError = useRouteError() as ReactRouterError;
  console.log(`################## ${error} #################`);

  useEffect(() => {
    document.title = "Error | Abun"
  }, []);

  return (
    <section className={"error-page"}>
      <NewAuthPageNavbar />
      <div className={"card px-6 has-text-centered"}>
        <div className={"error-icon"}>🚧</div>
        <h1 className={"is-size-3 has-text-weight-bold"}>{`Whoops:(`}</h1>
        <p className={"has-text-centered"}>
          Our AI might have stumbled upon a digital maze 🥺 <br />
          While we untangle the code, take a stroll back to the Keyword Research page.
        </p>
        <Link to={pageURL['createArticle']} className={"button is-primary mt-6"}>Go Back</Link>
      </div>
    </section>
  );
}
