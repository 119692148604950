import "./ArchiveArticles.scss";

import {Link, useLoaderData} from "react-router-dom";
import {useMutation, useQuery} from '@tanstack/react-query'
import {ColumnDef, createColumnHelper, RowModel, RowData} from "@tanstack/react-table";

import {pageURL} from "../routes";
import GenericButton from "../../components/GenericButton/GenericButton";
import Icon from "../../components/Icon/Icon";
import AbunTable, {IndeterminateCheckbox} from "../../components/AbunTable/AbunTable";
import {useEffect, useState} from "react";
import { loadArchiveArticleTitlesQuery, archiveBulkArticleMutation } from "../../utils/api";
import AbunModal from "../../components/AbunModal/AbunModal";
import loadingIcon from "../../assets/images/loadingIcon.webp"

interface ArchiveArticlesPageData {
	user_verified: boolean
	has_active_website: boolean
    total_archive_articles: number
}

interface ArchiveArticlesProps {
    total_archive_articles: number
}

type ArchiveArticlesTitle = {
	articleUID: string
	articleTitle: string
	keyword: string
	keywordTraffic: number | null
	internalLinks: number | null
	externalLinks: number | null
	images: number | null
	wordCount: number | null
    isArchived: boolean
}

function NoArchiveArticleMessage() {
    return (
		<div className={"notification is-success no-website-message-box mb-4"}>
			No Article is on Archiver. Want to visit <Link to={pageURL['articles']}><b>Articles</b></Link> Page?
		</div>
	)
}

function ArchiveArticlesData({total_archive_articles}: ArchiveArticlesProps) {
	// ----------------------- NON STATE CONSTANTS -----------------------
	const pageSizes = [5, 10, 15, 30, 50, 100, 500];

	// ----------------------- STATES -----------------------
	const [modalText, setModalText] = useState("");
	const [tableData, setTableData] = useState<Array<ArchiveArticlesTitle>>([]);
    const [requestModalActive, setRequestModalActive] = useState(false);
    const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
    const [disableArchiveArticleButton, setDisableArchiveArticleButton] = useState(true);

	// ----------------------- EFFECTS -----------------------
	const {isLoading, error, data} = useQuery(loadArchiveArticleTitlesQuery());
    const archiveSelectedArticles = useMutation(archiveBulkArticleMutation);

	useEffect(() => {
		if (data) {
			setTableData((data as any)['data']['title_data']);
		}
	}, [data]);

	if (error) {
		// Called when all retries are exhausted.
		return <p>Error!</p>

	} else if (isLoading) {
		return (
			<p style={{textAlign: "center", fontSize: "1.3rem"}}>
				Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"}/>
			</p>
		)

	} else {
		// ================== Generate table data and render AbunTable component ==================
		const columnHelper = createColumnHelper<ArchiveArticlesTitle>();

		const columnDefs: ColumnDef<any, any>[] = [
      columnHelper.accessor((row: ArchiveArticlesTitle) => row.articleTitle, {
        id: 'checkbox',
        header: ({table}) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({row}) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            name={"articleSelection"}
            value={row.original.articleUID}
          />
        ),
        enableGlobalFilter: true,
      }),
			columnHelper.accessor((row: ArchiveArticlesTitle) => row.articleTitle, {
				id: 'articleTitle',
				header: "Article Title",
				cell: info => info.getValue(),
				enableGlobalFilter: true,
			}),
			columnHelper.accessor((row: ArchiveArticlesTitle) => row.keyword, {
				id: 'keyword',
				header: "Keyword",
				cell: info => info.getValue(),
				enableGlobalFilter: true,
			}),
			columnHelper.accessor((row: ArchiveArticlesTitle) => row.wordCount, {
				id: 'wordCount',
				header: () => (<div style={{textAlign: "center"}}>Words</div>),
				cell: info => info.getValue() === null ? "---" : info.getValue(),
				enableGlobalFilter: false,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.accessor((row: ArchiveArticlesTitle) => row.keywordTraffic, {
				id: 'keywordTraffic',
				header: () => (<div style={{textAlign: "center"}}>Keyword Traffic</div>),
				cell: info => info.getValue() === null ? "---" : info.getValue(),
				enableGlobalFilter: false,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.accessor((row: ArchiveArticlesTitle) => row.internalLinks, {
				id: 'internalLinks',
				header: () => (<div style={{textAlign: "center"}}>Internal Links</div>),
				cell: info => info.getValue() === null ? "---" : info.getValue(),
				enableGlobalFilter: false,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.accessor((row: ArchiveArticlesTitle) => row.externalLinks, {
				id: 'externalLinks',
				header: () => (<div style={{textAlign: "center"}}>External Links</div>),
				cell: info => info.getValue() === null ? "---" : info.getValue(),
				enableGlobalFilter: false,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.accessor((row: ArchiveArticlesTitle) => row.images, {
				id: 'images',
				header: () => (<div style={{textAlign: "center"}}>Images</div>),
				cell: info => info.getValue() === null ? "---" : info.getValue(),
				enableGlobalFilter: false,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.display({
				id: 'unarchive_articles',
				cell: cellProps => {
                    if (cellProps.row.original.isArchived){
                        return <GenericButton text={"UnArchive Article"}
                                                                    type={"success"}
                                                                    width={"140px"}
                                                                    additionalClassList={["is-small", "more-rounded-borders"]}
                                                                    clickHandler={() => {
                                                                        setModalText("Processing request. Please wait...");
                                                                        setRequestModalActive(true);

                                                                        let articleUIDArray: Array<string> = []
                                                                        articleUIDArray.push(cellProps.row.original.articleUID);

                                                                        archiveSelectedArticles.mutate({articlesUID: articleUIDArray, archiveType: "unarchive"}, {
                                                                            onSuccess: () => {
                                                                                setRequestModalActive(false);
                                                                                setTableData(
                                                                                    tableData.filter(
                                                                                        value => !articleUIDArray.includes(value.articleUID)
                                                                                    )
                                                                                )
                                                                            },
                                                                            onError: (error) => {
                                                                                setRequestModalActive(false);
                                                                                // setDisableArchiveArticleButton(true);
                                                                                console.error(error);
                                                                            }
                                                                        })
                                                                    }}/>
                    } else {
                        return <GenericButton text={"UnArchive Article"}
                                                                    type={"success"}
                                                                    width={"140px"}
                                                                    additionalClassList={["is-small", "more-rounded-borders"]}
                                                                    disable={true}
                                                                    clickHandler={() => {
                                                                        console.log("Article is not archived");
                                                                    }}/>
                    }
                },
				enableGlobalFilter: false,
				meta: {
					align: 'center',
				}
			}),
		]
  
    function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
		if (rowModel.rows.length){
			setDisableArchiveArticleButton(false)

		} else {
			setDisableArchiveArticleButton(true)
		}
    }

		return (
			<>

                {/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
				<AbunModal active={requestModalActive}
									 headerText={""}
									 closeable={false}
									 hideModal={() => {
										 setRequestModalActive(false)
									 }}>
									 <div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
										 <img className={"image"} src={loadingIcon} alt="loading" />
									 </div>
					<p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
				</AbunModal>

				{/* ------------------------------ ARTICLE TITLE TABLE ------------------------------ */}
				<AbunTable tableContentName={"Article Titles"}
									 tableData={tableData}
									 columnDefs={columnDefs}
									 pageSizes={pageSizes}
									 initialPageSize={pageSizes[1]}
									 noDataText={total_archive_articles === 0 ?
										 "No article in the Archiver" :
										 ""}
									 searchboxPlaceholderText={"Search for Title & Keywords..."}
                   rowCheckbox={true}
                   selectedRowsSetter={selectedRowsSetter}
                //    buttons={[
                //     {
                //       text: "UnArchive Selected Articles",
                //       type: "success",
				// 	  isDisabled: disableArchiveArticleButton,
				// 	  extraClassName: "abun-table-button-sm",
                //       clickHandler: () => {						  
				// 		  if (selectedRows) {
				// 			setModalText("Processing request. Please wait...");
				// 			setRequestModalActive(true);

				// 			let articleUIDArray: Array<string> = selectedRows.rows.map(row => {
				// 				return (row.original as ArchiveArticlesTitle).articleUID
				// 			});

				// 			archiveSelectedArticles.mutate({articlesUID: articleUIDArray, archiveType: "unarchive"}, {
				// 				onSuccess: () => {
				// 					setRequestModalActive(false);
				// 					setTableData(
				// 						tableData.filter(
				// 							value => !articleUIDArray.includes(value.articleUID)
				// 						)
				// 					)
				// 				},
				// 				onError: (error) => {
				// 					setRequestModalActive(false);
				// 					// setDisableArchiveArticleButton(true);
				// 					console.error(error);
				// 				}
				// 			})
				// 		}
                //       }
                //     },
                //    ]}/>
				   bulkActions={!selectedRows?.rows.length ? [] : [
						{
							text: "UnArchive",
							isDisabled: disableArchiveArticleButton,
							extraClassName: "abun-table-button-sm"
						},
					]}/>
			</>
		)
	}
}


export default function ArchiveArticles() {
    // ----------------------- PAGE DATA -----------------------
	const pageData = useLoaderData() as ArchiveArticlesPageData;

    useEffect(() => {
		document.title = "Archived Articles | Abun"
	}, []);

    return (
        <>
            {!pageData.total_archive_articles && <NoArchiveArticleMessage />}
            <h2 className={"is-size-3 w-100"}>Archived Articles</h2>
            <div className="card mt-4 article-page-table-card">
				<div className="card-content">
					<div className="content is-flex is-flex-direction-column">
						<ArchiveArticlesData total_archive_articles={pageData.total_archive_articles}/>
					</div>
				</div>
			</div>
        </>
    )
}
