import './NewAuthBase.scss'

import Icon from "../../components/Icon/Icon";
import {Outlet} from "react-router-dom";
import NewAuthPageNavbar from "../../components/NewAuthPageNavbar/NewAuthPageNavbar";

export default function NewAuthBase() {
	return (
		<div className={"authbase-container"}>
			{/* ----------------------- NAVBAR ----------------------- */}
			<NewAuthPageNavbar/>

			{/* ----------------------- MAIN BODY ----------------------- */}
            <div className="container">
                <Outlet/>
            </div>
		</div>
	)
}
