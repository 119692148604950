import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export default function BasicTimePicker(props: {
    label: string,
    isOpen: boolean,
    defaultDate: Dayjs,
    className?: string
    onChange: (value: any) => void,
    onClose: () => void,
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
                <TimePicker label={props.label}
                    value={props.defaultDate ? props.defaultDate : dayjs()}
                    open={props.isOpen}
                    className={props.className}
                    onChange={(scheduleDate) => props.onChange(scheduleDate)}
                    onClose={props.onClose}
                    openTo="hours" />
            </DemoContainer>
        </LocalizationProvider>
    );
}