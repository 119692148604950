import { useEffect, useRef, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import checkCircle from "../../assets/images/check-mark-circle.png";
import crossCircle from "../../assets/images/cross-circle.png";
import loadingIcon from "../../assets/images/loadingIcon.webp";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunTable from "../../components/AbunTable/AbunTable";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import GenericButton from "../../components/GenericButton/GenericButton";
import Icon from "../../components/Icon/Icon";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import {
	cardPaymentFailedMutation,
	getAllPlanDataQuery,
	getSubscriptionHistoryQuery,
	planChangeMutation,
	purchasePlanMutation,
	stripePortalLinkQuery,
} from "../../utils/api";
import "./SubscriptionPlanManager.scss";


interface PlansPageData {
	has_payment_method: boolean
	has_customer_id: boolean
	current_active_price_id: string
	stripe_customer_portal: string
	latest_invoice_is_open: boolean
	usage_stats: Array<UsageStat>
}

interface UsageStat {
	title: string
	value: number
	max: number
}

interface PlanData {
	id: string
	name: string
	metadata: PlanMetadata
	price_id: string
	price_amount: number
	currency_code: string
}

interface PlanMetadata {
	max_articles: number
	max_titles: number
	max_keywords: number
	position: number
	websites: number
	description: string
	popular: boolean
}

interface SubscriptionHistoryData {
	active: boolean
	subscription_id: string
	plan_name: string
	currency: string
	amount: number
	created: string
	current_period_start: string
	current_period_end: string
}

export default function SubscriptionPlanManager() {
	// ------------------ NON STATE CONSTANTS ------------------
	const pageSizes = [5, 10, 15, 30, 50, 100, 500];

	// ------------------ PAGE DATA ------------------
	const pageData = useLoaderData() as PlansPageData;

	// ------------------ STATES ------------------
	const [stripe, setStripe] = useState<Stripe | null>(null);
	const [currentPriceID, setCurrentPriceID] = useState(pageData.current_active_price_id);
	const [planCardsData, setPlanCardsData] = useState<Array<PlanData>>([]);
	const [tableData, setTableData] = useState<Array<SubscriptionHistoryData>>([]);
	const [purchaseConfirmModalActive, setPurchaseConfirmModalActive] = useState(false);
	const [selectedPlanName, setSelectedPlanName] = useState("");
	const [selectedPriceID, setSelectedPriceID] = useState("");
	const [currentPriceAmount, setCurrentPriceAmount] = useState(null);

	// used for disabling modal buttons and other purposes while plan purchase is underway
	const [planPurchaseUnderway, setPlanPurchaseUnderway] = useState(false);
	const [
		stripePortalLink,
		setStripePortalLink
	] = useState<string | null | undefined>("");
	// const [hasPaymentMethod, setHasPaymentMethod] = useState(pageData.has_payment_method);

	// ------------------ QUERIES ------------------
	const allPlanData = useQuery(getAllPlanDataQuery());
	const subscriptionHistory = useQuery(getSubscriptionHistoryQuery());
	const getStripePortalLink = useQuery(stripePortalLinkQuery());

	// ------------------ MUTATIONS ------------------
	const purchasePlan = useMutation(purchasePlanMutation);
	const changePlan = useMutation(planChangeMutation);
	const cardPaymentFailed = useMutation(cardPaymentFailedMutation);

	// ------------------ EFFECTS ------------------
	useEffect(() => {
		document.title = "Manage Subscription | Abun"
	}, []);

	useEffect(() => {
		loadStripe(
			"pk_test_51NhU8USAw4DIVBDn6UzsRQhq3CaIccfPuIbSkps8WW1nOyQZ7kDiHzZmHROW0xj87GJJACX8qXpbJgoRsx3hxVX200W3KJGdMO"
		).then(value => {
			setStripe(value);
		});
	}, [])

	useEffect(() => {
		if (getStripePortalLink.data) {
			setStripePortalLink((getStripePortalLink.data as any)['data']['url']);
		}
	}, [getStripePortalLink.data]);


useEffect(() => {
    if (allPlanData.data) {
        setPlanCardsData((allPlanData.data as any)['data'] as Array<PlanData>);

        const plans = (allPlanData.data as any)['data'];
        // Find the active plan using the latest 'currentPriceID'
        const activePlan = plans.find(plan => plan.price_id === currentPriceID);
                
        if (activePlan) {
            setCurrentPriceAmount(activePlan.price_amount);
        }
    }

    document.querySelector(".abun-table-content")?.classList.add("no-bulk-action");
    document.querySelector(".abun-table-filter-buttons")?.remove();
}, [allPlanData.data, currentPriceID]);

console.log(currentPriceAmount)





	useEffect(() => {
		if (subscriptionHistory.data) {
			setTableData((subscriptionHistory.data as any)['data'])
		}
	}, [subscriptionHistory.data]);

	// ---------------------------- REFS ----------------------------
	const errorAlertRef = useRef<any>(null);
	const successAlertRef = useRef<any>(null);

	// ------------------ TABLE COLUMN DEFS ------------------
	const columnHelper = createColumnHelper<SubscriptionHistoryData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: SubscriptionHistoryData) => row.plan_name, {
			id: 'planName',
			header: "Plan",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.display({
			id: 'planAmount',
			header: "Amount",
			cell: props => {
				if (props.row.original.currency === 'inr') {
					return `INR ${props.row.original.amount / 100}`
				} else {
					return `$${props.row.original.amount / 100}`
				}
			},
			enableGlobalFilter: false,
		}),
		// columnHelper.accessor((row: SubscriptionHistoryData) => row.created, {
		// 	id: 'subscriptionCreatedDate',
		// 	header: "Purchased On",
		// 	cell: info => info.getValue(),
		// 	enableGlobalFilter: false,
		// }),
		columnHelper.display({
			id: 'subscriptionRenewalDate',
			header: "Renews On",
			cell: props => {
				if (props.row.original.active) {
					return props.row.original.current_period_end
				} else {
					return "---"
				}
			},
			enableGlobalFilter: false,
		}),
		columnHelper.display({
			id: 'subscriptionStatus',
			header: () => (<div className={"has-text-centered"}>Status</div>),
			cell: props => {
				if (props.row.original.active) {
					// return <span className={"tag is-success"}>Active</span>
					return <span className={"has-text-success has-text-weight-bold"}>Active</span>
				} else {
					// return <span className={"tag is-danger"}>Cancelled</span>
					return <span className={"has-text-danger has-text-weight-bold"}>Cancelled</span>
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
	]

	// =======================================================
	// ---------------------- MAIN CODE ----------------------
	// =======================================================

	function resetPaymentActions() {
		setPlanPurchaseUnderway(false);
		setSelectedPlanName("");
		setSelectedPriceID("");
		setPurchaseConfirmModalActive(false);
	}

	function paymentSuccessActions(newPriceID: string) {
		setCurrentPriceID(newPriceID);
		subscriptionHistory.refetch().then();
		setPlanPurchaseUnderway(false);
		setSelectedPlanName("");
		setSelectedPriceID("");
		setPurchaseConfirmModalActive(false);
		successAlertRef.current?.show(
			`Your subscription plan has been switched successfully!`
		);
	}

	function paymentFailedActions(errorMsg: string | undefined) {
		setPlanPurchaseUnderway(false);
		setSelectedPlanName("");
		setSelectedPriceID("");
		setPurchaseConfirmModalActive(false);
		if (errorMsg) {
			errorAlertRef.current?.show(
				`Error while trying to purchase new plan: ${errorMsg}`
			);
		} else {
			errorAlertRef.current?.show(
				"Oops! Something went wrong while processing your payment. Please contact support for further assistance"
			);
		}
	}

	function purchasePlanHandler(planName: string, priceID: string) {
		errorAlertRef.current?.close();
		successAlertRef.current?.close();
		setPlanPurchaseUnderway(true);

		// Upgrade/Downgrade if user has a payment method existing in stripe. Otherwise send them for checkout.
		if (pageData.has_payment_method) {
			// --------------------------- UPGRADE/DOWNGRADE USER PLAN ---------------------------
			changePlan.mutate(priceID, {
				onSuccess: (response) => {
					let success: boolean = response['data']['success']
					if (success) {
						let newPriceID: string = response['data']['new_price_id'];
						// Wait for some time to allow stripe webhook events to be done.
						setTimeout(() => {
							// refetch plan cards and table data in order to
							paymentSuccessActions(newPriceID);
						}, 7000);

					} else {
						let clientSecret: string = response['data']['client_secret']
						let paymentIntentStatus: string = response['data']['pi_status']
						let paymentIntentID: string = response['data']['pi_id']
						if ((paymentIntentStatus === 'requires_action') || (paymentIntentStatus === 'requires_payment_method')) {
							// This is for actions like 3D Secure auth.
							stripe?.handleNextAction({ clientSecret: clientSecret }).then((result) => {
								if (result.error) {
									// 3D Secure verification failed or card was declined
									if (result.error.code === "card_declined") {
										cardPaymentFailed.mutate(result.error.payment_intent?.id as string, {
											onSuccess: () => {
												paymentFailedActions(result.error.message);
											},
											onError: () => {
												paymentFailedActions(
													`Please contact us through support and let us know about this incident along with this id: ${paymentIntentID}`
												);
											}
										});
									} else {
										paymentFailedActions(
											`Please contact us through support and let us know about this incident along with this id: ${paymentIntentID}`
										);
									}
								} else {
									// 3D Secure verification successful
									paymentSuccessActions(priceID);
								}
							});
						} else if (paymentIntentStatus === 'card_declined') {
							// Card was declined
							cardPaymentFailed.mutate(paymentIntentID, {
								onSuccess: () => {
									paymentFailedActions(
										"Your card was declined. Please contact your card issuer for more information or try " +
										"purchasing again with a different card. You can update your card details by visiting Stripe " +
										"Customer Portal using the button on this page."
									);
								},
								onError: () => {
									paymentFailedActions(
										`Please contact us through support and let us know about this incident along with this id: ${paymentIntentID}`
									);
								}
							});
						} else {
							// Unhandled actions
							console.error(`Recieved unknown paymentIntentStatus '${paymentIntentStatus}'`);
							paymentFailedActions(undefined);
						}
					}
				},
				onError: (error) => {
					console.error(`Error while trying to purchase new plan: ${error}`);
					resetPaymentActions();
					errorAlertRef.current?.show(`Error while trying to purchase new plan: ${error}`);
				}
			});
		} else {
			// --------------------------- START CHECKOUT SESSION ---------------------------
			let successURL = process.env.REACT_APP_STRIPE_CHECKOUT_SUCCESS_URL;
			let cancelURL = process.env.REACT_APP_STRIPE_CHECKOUT_CANCEL_URL;

			if (successURL && cancelURL && priceID) {
				purchasePlan.mutate({
					priceID: priceID,
					successURL: successURL,
					cancelURL: cancelURL
				}, {
					onSuccess: (response) => {
						window.location.href = response['data']['checkout_url'];
					},
					onError: (error) => {
						console.error(`Stripe Checkout Failed: ${error}`);
					}
				});
			} else {
				errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for support.");
			}
		}
	}

	function getUsageStatIcon(statName: string) {
		if (statName === "Websites Connected") {
			return <Icon iconName={"web-globe"} />

		} else if (statName === "Article Titles") {
			return <Icon iconName={"paragraph"} />

		} else if (statName === "Articles") {
			return <Icon iconName={"book"} />

		} else {
			return <Icon iconName={"paragraph"} />
		}
	}


	// ||---Subscription --- Plan --- Button---||
	const getButtonPlan = (plan) => {
		if (currentPriceAmount == null) {
			return "Price Unavailable";
		} else if (currentPriceAmount == plan.price_amount) {
			return "Current Plan";
		} else if (currentPriceAmount == 0) {
			return "Subscribe Now";
		} else if (currentPriceAmount != 0 && plan.price_amount == 0) {
			return "Switch to Free Plan";
		} else if (currentPriceAmount > plan.price_amount) {
			return "Downgrade";
		} else if (currentPriceAmount < plan.price_amount) {
			return "Upgrade";
		}
		return ""; // Default case
	};

	return (
		<>
			{/* -------------------------- PLAN PURCHASE CONFIRMATION -------------------------- */}
			<AbunModal active={purchaseConfirmModalActive}
				headerText={""}
				closeable={false}
				hideModal={() => {
					setPurchaseConfirmModalActive(false)
				}}>
				{planPurchaseUnderway ?
					<>
						<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
							<img className={"image"} src={loadingIcon} alt="loading" />
						</div>
						<p className={"has-text-centered is-size-4"}>
							{/* Moving to <b>{selectedPlanName.toUpperCase()}</b> Plan. Please wait... */}
							Moving to <b>{selectedPlanName === "Trial" ? "Free" : selectedPlanName.toUpperCase()}</b> Plan. Please wait...
						</p>
					</> :
					<>
						<h2 className={"is-size-4 font-secondary"}>
							Confirm Moving to <b>{selectedPlanName === "Trial" ? "Free" : selectedPlanName.toUpperCase()}</b> Plan?
						</h2>
						<p className={"mt-4"}>
							Once confirmed you will be charged full amount on your current default card and moved
							to <b>{selectedPlanName === "Trial" ? "Free" : selectedPlanName.toUpperCase()}</b> plan immeditately. All your current usage values will be reset back to
							0 and your new billing cycle will start from today.
							<br />
							<br />
							<b>NOTE:</b> You can change your default card from Stripe Customer Portal before proceeding.
						</p>
						<div className={"mt-5"}>
							<GenericButton text={"Confirm"}
								type={"success"}
								clickHandler={() => {
									purchasePlanHandler(selectedPlanName, selectedPriceID);
								}}
							/>
							<GenericButton text={"Cancel"}
								type={"danger"}
								additionalClassList={["ml-5"]}
								clickHandler={() => {
									setPurchaseConfirmModalActive(false);
									setSelectedPlanName("");
									setSelectedPriceID("");
								}} />
						</div>
					</>}
			</AbunModal>

			{/* -------------------------- PAYMENT FAILURE ALERT -------------------------- */}
			{(pageData.latest_invoice_is_open && stripePortalLink) &&
				<div className={"card w-100 has-background-danger mb-5"}>
					<div className={"card-content"}>
						<div className={"content"}>
							<p className={"has-text-centered has-text-white"}>
								Your previous plan renewal <b>failed</b> due to card issue.<br />
								To stay on current plan please update your payment details
								using <a className={"has-text-white is-underlined has-text-weight-bold"}
									target={"_blank"}
									rel={"noreferrer"}
									href={stripePortalLink}>Stripe Customer Portal</a>. Failing to do so will result in
								cancellation of your current plan.
							</p>
						</div>
					</div>
				</div>}

			{/* -------------------------- PLAN CARDS -------------------------- */}
			<div className={"card w-100"}>
				<div className={"card-content"}>
					<div className={"offer-card"}>
						<h2 className="offer">Limited Time Offer: 50% OFF for First Month. No Coupon Required.</h2>
					</div>
					{allPlanData.isLoading ? <p className={"has-text-centered"} style={{ fontSize: "1.5em" }}>
						Loading Data. Please Wait...&nbsp;&nbsp;<Icon iconName={"spinner"} width={"1.5em"} height={"1.5em"} />
					</p> :
						<div className={"subscription-manager-plan-cards-container"}>
							{planCardsData.map(plan => {
								return (
									<div
										className={plan.name==="Pro Max" ? "plan-card popular" : "plan-card"}
										key={plan.id}
										id={"basic"}>
										<p className={"plan-name"}>{plan.name==="Trial" ? "Free" : plan.name.toUpperCase()}</p>
										{/* Cancel Price & it is hidden for free plan*/}
										{plan.name !== "Trial" && (<p className={"cancel-pricing"}>{plan.currency_code === 'inr' ? '₹' : '$'} {plan.currency_code === "inr" ? (plan.price_amount / 100).toString().replace("000", "K") : (plan.price_amount / 100).toString().replace("000", "")}</p>)}
										<p className={"pricing"}>
											{plan.currency_code === 'inr' ? '₹' : '$'}{plan.currency_code === "inr" ? `${((plan.price_amount / 100 /2) / 1000)}K` : `${((plan.price_amount / 100 /2))}`}
											<span className={"pricing-suffix"}>per month</span>
										</p>
										{/* <p>{plan.metadata.description}</p> */}
										<div className={"plan-details"}>
											{/* <p className={"plan-details--item"}><b>{plan.metadata.websites}</b> Website</p> */}
											{/* <p className={"plan-details--item"}><b>{plan.metadata.max_titles}</b> Article Titles</p>
												<p className={"plan-details--item"}><b>10</b> Competitors</p> */}
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													<img src={checkCircle} alt="Check Mark" />
												</span>
												&nbsp;<b>{plan.metadata.max_articles}</b>
												&nbsp;Articles
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													<img src={checkCircle} alt="Check Mark" />
												</span>
												&nbsp;<b>{plan.metadata.max_keywords}</b>
												&nbsp;Keyword Research
											</p>
											<hr />
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													<img src={checkCircle} alt="Check Mark" />
												</span>
												&nbsp;All Type of Articles
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													<img src={checkCircle} alt="Check Mark" />
												</span>
												&nbsp;Auto Internal/External Linking
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													<img src={checkCircle} alt="Check Mark" />
												</span>
												&nbsp;Auto Featured Images
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													<img src={checkCircle} alt="Check Mark" />
												</span>
												&nbsp;Images, Tables & List Content
											</p>
											<hr />
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													<img src={checkCircle} alt="Check Mark" />
												</span>
												&nbsp;AI Keyword Research
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													<img src={checkCircle} alt="Check Mark" />
												</span>
												&nbsp;Manual Keywords
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													{
														plan.name === "Trial" ? <img src={crossCircle} alt="Cross Mark" /> :
														<img src={checkCircle} alt="Check Mark" />
													}
												</span>
												&nbsp;Competitor Keywords
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													{
														plan.name === "Trial" ? <img src={crossCircle} alt="Cross Mark" /> :
														<img src={checkCircle} alt="Check Mark" />
													}
												</span>
												&nbsp;Longtail Keywords
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													{
														plan.name === "Trial" ? <img src={crossCircle} alt="Cross Mark" /> :
														<img src={checkCircle} alt="Check Mark" />
													}
												</span>
												&nbsp;GSC Keywords
											</p>
											<hr />
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													{
														plan.name === "Trial" ? <img src={crossCircle} alt="Cross Mark" /> :
														<img src={checkCircle} alt="Check Mark" />
													}
												</span>
												&nbsp;Schedule & Automation
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													{
														plan.name === "Trial" ? <img src={crossCircle} alt="Cross Mark" /> :
														<img src={checkCircle} alt="Check Mark" />
													}
												</span>
												&nbsp;WordPress Integration
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													{
														plan.name === "Trial" ? <img src={crossCircle} alt="Cross Mark" /> :
														<img src={checkCircle} alt="Check Mark" />
													}
												</span>
												&nbsp;Webflow Integration
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													{
														plan.name === "Trial" ? <img src={crossCircle} alt="Cross Mark" /> :
														<img src={checkCircle} alt="Check Mark" />
													}
												</span>
												&nbsp;WIX Integration
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													{
														plan.name === "Trial" ? <img src={crossCircle} alt="Cross Mark" /> :
														<img src={checkCircle} alt="Check Mark" />
													}
												</span>
												&nbsp;Google Search Console
											</p>
											<p className={"plan-details--item"}>
												<span className={"icon is-small"}>
													{
														plan.name === "Trial" ? <img src={crossCircle} alt="Cross Mark" /> :
														<img src={checkCircle} alt="Check Mark" />
													}
												</span>
												&nbsp;Faster Google Index
											</p>
											                                                                     {/* applied css for free plan btn when user is on higher plan*/}
											<button  className={`button is-primary is-outlined plan-details--buy-button ${currentPriceAmount !== 0 && plan.price_amount === 0 ? "switch-to-free-plan"  : "" }`}
												disabled={currentPriceID === plan.price_id}
												onClick={() => {
													setSelectedPlanName(plan.name);
													setSelectedPriceID(plan.price_id);
													if (pageData.has_payment_method) {
														// if user has a payment method on file, show the plan change alert
														// modal for confirmation
														setPurchaseConfirmModalActive(true);
													} else {
														// otherwise send them directly to checkout page.
														purchasePlanHandler(plan.name, plan.price_id);
													}
												}}>
										{getButtonPlan(plan)}
										
											</button>
										</div>
									</div>
								)
							})}
						</div>}
				</div>
			</div>

			{/* -------------------------- ACTIVE SUBSCRIPTION TABLE -------------------------- */}
			<div className={"card w-100 mt-5"}>
				<div className={"card-content"}>
					<AbunTable tableContentName={"Subscriptions"}
						tableData={tableData}
						tableName="Your Subscriptions"
						columnDefs={columnDefs}
						pageSizes={pageSizes}
						initialPageSize={pageSizes[6]}
						noDataText={"No subscription data available."}
						searchboxPlaceholderText={"Search subscriptions..."} />

				</div>
			</div>

			{/* -------------------------- Usage Stats -------------------------- */}
			<section id={"usage-stats"} className={"usage-stats-section mt-6"}>
				<label htmlFor={"usage-stats"} className="section-label">
					Usage Stats for this month:
				</label>
				<div className={"card w-100 mt-2"}>
					<div className={"card-content"}>
						<div className={"content"}>
							<div className={"usage-stats-container"}>
								{pageData.usage_stats.map(stat => {
									// hiding websites connected and article titles stats
									if (stat.title === "Websites Connected" || stat.title === "Article Titles") {
										// skip this stat
										return <></>;
									}
									let percent = Math.round(stat.value * 100 / stat.max);
									return (
										<div className="usage-stats-item" key={stat.title}>
											<p className={"usage-stats-item--title"}>{stat.title}</p>
											<progress className="progress is-primary" value={stat.value} max={stat.max}></progress>
											<div className={"usage-stats-item--info"}>
												<p>
													{getUsageStatIcon(stat.title)}&nbsp;&nbsp;&nbsp;{stat.value} (<span className={
														percent >= 100 ? "has-text-warning" : "has-text-black"
													}>{percent}%</span>)
												</p>
												<p>{stat.max}</p>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* -------------------------- STRIPE CUSTOMER PORTAL -------------------------- */}
			{stripePortalLink && <div className={"card w-100 mt-5"}>
				<div className={"card-content"}>
					<div className={"content is-flex is-flex-direction-column is-align-items-center"}>
						<h2 className={"is-size-4 has-text-centered"}>
							Manage your subscriptions, card details and download invoices
							through <b>Stripe Customer Portal</b>.<br />
							Use the button below to visit your Customer Portal.
						</h2>
						<p className={"mt-5"}><b>NOTE:</b> if you get a <b>page expired</b> message upon visting
							stripe customer portal, please reload this page and try visiting it again.
						</p>
						<Link to={stripePortalLink}
							target={"_blank"}
							className={"button is-primary is-large mt-6 has-text-weight-bold btns-stripe-portal"}>
							Stripe Customer Portal
						</Link>
					</div>
				</div>
			</div>}
			<ErrorAlert ref={errorAlertRef} />
			<SuccessAlert ref={successAlertRef} />
		</>
	)
}
