import React, { ReactNode } from 'react';
import AbunButton from '../AbunButton/AbunButton';
import Icon from "../Icon/Icon";
import './AbunConfirmationModal.scss';

interface AbunConfirmationModalProps {
    active: boolean;
    headerText: string;
    children: ReactNode;
    closeable: boolean;
    wideModal?: boolean;
    modelWidth?: string;
    positiveButtonText?: string;
    negativeButtonText?: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const AbunConfirmationModal: React.FC<AbunConfirmationModalProps> = (props) => {
    return (
        <div className={props.active ? "modal is-active" : "modal"}>
            <div className="modal-background"></div>
            <div className={`modal-content ${props.wideModal ? "wide-modal" : ""}`} style={{ width: props.modelWidth }}>
                <div className="card abun-modal-card">
                    {props.closeable &&
                        <button className="abun-modal-close" onClick={props.onCancel}>
                            <Icon additionalClasses={[`${props.wideModal ? "icon-white" : ""}`]} iconName="delete-circle" />
                        </button>}
                    <div className={props.headerText !== "" ? "card-header" : "card-header is-hidden"}>
                        <div className="card-header-title is-justify-content-center">
                            <p>{props.headerText}</p>
                        </div>
                    </div>
                    <div className="card-content">
                        <div className="content">
                            {props.children}
                        </div>
                        <div className="buttons is-right">
                            <AbunButton type='light' clickHandler={props.onCancel}>
                                {props.negativeButtonText ?? "Cancel"}
                            </AbunButton>
                            <AbunButton type='primary' clickHandler={props.onConfirm}>
                                {props.positiveButtonText ?? "Confirm"}
                            </AbunButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AbunConfirmationModal;
