import {NavLink, useLoaderData, useNavigate} from "react-router-dom";
import "./Profile.scss";
import {useEffect, useRef, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {
	deleteCurrentWebsite,
	emailPreferenceMutation,
	resendVerificationEmailMutation,
	saveUserDetailsMutation
} from "../../utils/api";
import Input from "../../components/Input/Input";
import TimezoneSelect from "react-timezone-select";
import GenericButton from "../../components/GenericButton/GenericButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import Icon from "../../components/Icon/Icon";
import Card from "../../components/Card/Card";
import AbunButton from "../../components/AbunButton/AbunButton";
import {pageURL} from "../routes";
import AbunModal from "../../components/AbunModal/AbunModal";

interface ProfilePageData {
	verified: boolean
	username: string
	website: string | null
	email: string
	tz: string
	send_notif_email: boolean
	content_plan_generation_status: string
	plan_name: string
}

export default function Profile() {
	// ---------------------------- PAGE DATA ----------------------------
	const pageData: ProfilePageData = useLoaderData() as ProfilePageData;

	// ---------------------------- REACT ROUTER ----------------------------
	const navigate = useNavigate();

	// ---------------------------- STATES ----------------------------
	const [username, setUsername] = useState(pageData.username);
	const [sendNotifEmail, setSendNotifEmail] = useState(pageData.send_notif_email);
	const [
		selectedTimezone,
		setSelectedTimezone
	] = useState(pageData.tz === "UTC" ? "Etc/GMT" : pageData.tz);
	const [discWebsiteInput, setDiscWebsiteInput] = useState("");
	const [disconnectWebsiteModelActive, setDisconnectWebsiteModelActive] = useState(false);

	// ---------------------------- MUTATIONS ----------------------------
	const resendVerificationEmail = useMutation(resendVerificationEmailMutation);

	// -------------------------- REFS --------------------------
	const successAlertRef = useRef<any>(null);
	const errorAlertRef = useRef<any>(null);

	// ---------------------------- MUTATIONS ----------------------------
	const saveUserDetails = useMutation(saveUserDetailsMutation);
	const saveEmailPref = useMutation(emailPreferenceMutation);
	const deleteWebsite = useMutation({
		mutationKey: ['deleteCurrentWebsite'],
		mutationFn: deleteCurrentWebsite,
		cacheTime: 0,
		onSuccess: () => {
			window.location.reload();
			navigate(pageURL['createArticle']);
		},
		onError: (error) => {
			console.error(error);
			errorAlertRef.current?.show("Oops! Something went wrong. Please try again in some time.")
		}
	})

	// ---------------------------- EFFECTS ----------------------------
	useEffect(() => {
		document.title = "Profile | Abun"
	}, []);

	// ===================================================================
	// ---------------------------- MAIN CODE ----------------------------
	// ===================================================================
	return (
		<>
			<h2 className={"is-size-2 w-100"}>Manage Your Account Settings</h2>
			<div className={"card w-100 mt-5"}>
				<div className={"card-header"}>
					<h2 className={"card-header-title"}>Personal Settings</h2>
				</div>
				<div className={"card-content settings-card"}>
					<div className={"content"}>
						<div className={"profile-container"}>
							<label className={"label"}>
								Full Name:
								<Input value={username}
											 type={"text"}
											 className={"mt-2"}
											 placeholder={"Your full name (ex. John Doe)"}
											 onChange={setUsername}/>
							</label>
							<label className={"label mt-5"}>
								Email Address:
								<div>
									<div className={"profile-email-field mt-2"}>
										<input type="text"
													 defaultValue={pageData.email}
													 disabled={true}
													 className={"input disabled-color"}/>
										{pageData.verified && <Icon iconName={"green-checkmark-circle"}/>}
										{!pageData.verified &&
                        <button className={"button is-small is-primary mt-3"}
                                disabled={resendVerificationEmail.isLoading}
                                onClick={() => {
																	successAlertRef.current?.close();
																	errorAlertRef.current?.close();
																	resendVerificationEmail.mutate(undefined, {
																		onSuccess: () => {
																			successAlertRef.current?.show(
																				"Verification email has been sent to your registered email id successfully!"
																			);
																		},
																		onError: (error) => {
																			console.error(error);
																			errorAlertRef.current?.show("Oops! Something went wrong. Please try again later");
																		}
																	})
																}}>
                            Resend Verification Email
                        </button>
										}
									</div>
								</div>
							</label>
							<div className="select-wrapper mt-5">
								<label className={"label"}>
									Your Timezone:
									<TimezoneSelect
										value={selectedTimezone}
										className={"mt-2"}
										onChange={(tz) => {
											setSelectedTimezone(tz.value)
										}}
									/>
								</label>
							</div>
							<GenericButton text={"Save Details"}
														 type={"success"}
														 additionalClassList={["mt-6", "profile-save-button"]}
														 clickHandler={() => {
															 successAlertRef.current?.close();
															 errorAlertRef.current?.close();
															 saveUserDetails.mutate({username: username, tz: selectedTimezone}, {
																 onSuccess: () => {
																	 successAlertRef.current?.show("All user details saved successfully!");
																 },
																 onError: () => {
																	 errorAlertRef.current?.show(
																		 "Oops! Something went wrong :( Please try " +
																		 "again later or contact us for further support."
																	 );
																 }
															 })
														 }}/>
						</div>
					</div>
				</div>
			</div>

			{/* ------------------------- EMAIL SETTINGS ------------------------- */}
			<div className={"card w-100 mt-5"}>
				<div className={"card-header"}>
					<h2 className={"card-header-title"}>Email Settings</h2>
				</div>
				<div className={"card-content settings-card"}>
					<div className={"content"}>
						<div className="field">
							<input id="sendNotificationEmails"
										 type="checkbox"
										 className="switch is-rounded"
										 checked={sendNotifEmail}
										 onChange={() => {
											 saveEmailPref.mutate({send_notif_email: !sendNotifEmail}, {
												 onSuccess: () => {
													 setSendNotifEmail(!sendNotifEmail);
												 }
											 });
										 }}/>
							<label htmlFor="sendNotificationEmails">Send Notification Emails</label>
						</div>
					</div>
				</div>
			</div>

			{/* ------------------------- LOGOUT ------------------------- */}
			<div className={"card w-100 mt-5"}>
				<div className={"card-content settings-card"}>
					<div className={"content is-flex is-flex-direction-row is-justify-content-center is-align-items-center"}>
						<NavLink to={`/logout`} className="align-image-text-vertically is-primary button">
							<Icon iconName={"logout"}/>&nbsp;&nbsp;&nbsp;&nbsp;Logout from Abun
						</NavLink>
					</div>
				</div>
			</div>

			{/* ------------------------- DISCONNECT WEBSITE ALERT ------------------------- */}
			<AbunModal active={disconnectWebsiteModelActive}
								 headerText={"Disconnect Website"}
								 closeable={false}
								 hideModal={() => {
									setDisconnectWebsiteModelActive(false);
								 }}>
				<div>
					<h4 className={"is-size-4 has-text-centered"}>Are You Sure? Disconnect <b>{pageData.website}</b></h4>
					<p className={"has-text-centered mt-4"}>
						This irreversible action will result in the loss of access to all your generated Articles, Competitors, and Keywords data.
						Please note that your published articles will remain available on your website.
					</p>
					<p className={"has-text-centered mt-3"}>
						Not Sure? <a href="/contact-us">Contact Support Now</a>
					</p>
					<div className={"mt-6 has-text-centered"}>
						<AbunButton type={"danger"} clickHandler={() => {
							deleteWebsite.mutate();
						}}>
							Yes, Disconnect
						</AbunButton>
						<AbunButton type={"primary"} className={"ml-4"} clickHandler={() => {
							setDisconnectWebsiteModelActive(false);
						}}>
							Cancel
						</AbunButton>
					</div>
				</div>
			</AbunModal>

			{/* ------------------------- DISCONNECT WEBSITE ------------------------- */}
			{pageData.website && <Card cardHeaderTitle={"Disconnect Website"} className={"mt-5 w-100"}>
				<div className={"is-flex is-flex-direction-column is-justify-content-center is-align-items-center"}>
					<h3 className={"is-size-3 font-secondary has-text-centered"}>
						Disconnect <b className={"font-secondary"}>{pageData.website}</b> from Abun
					</h3>
					<p className={"is-size-5 has-text-centered mt-2"} style={{maxWidth: "700px"}}>
						Once disconnected you will lose access to all your generated Articles, Competitors and Keywords data.
						Posted articles will still be available on your site.
						This action can not be undone.
					</p>
					<p className={"is-size-5 has-text-centered mt-4"}>
						If you are sure you want to proceed, type <b className={"has-text-danger"}>{pageData.website}</b> below
						to unlock the action.
					</p>
					<Input value={discWebsiteInput} disabled={pageData.plan_name === "Trial"} type={"text"} className={"mt-4"} onChange={setDiscWebsiteInput}/>
					<AbunButton type={"danger"}
											className={"mt-6"}
											disabled={(discWebsiteInput.toLowerCase() !== pageData.website.toLowerCase()) || deleteWebsite.isLoading || pageData.plan_name === "Trial"}
											clickHandler={() => {
												setDisconnectWebsiteModelActive(true);
											}}>
						Disconnect&nbsp;<b>{pageData.website}</b>&nbsp;from Abun
					</AbunButton>
				</div>
			</Card>}

			<ErrorAlert ref={errorAlertRef}/>
			<SuccessAlert ref={successAlertRef}/>
		</>
	)
}
