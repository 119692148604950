import {ReactNode, useEffect, useState} from "react";
import Icon from "../Icon/Icon";

import "./AbunModal.scss"

interface AbunModalProps {
	active: boolean
	headerText: string
	children: ReactNode
	closeable: boolean
	wideModal?: Boolean
	modelWidth?: string
	hideModal: () => void;
}

export default function AbunModal(props: AbunModalProps) {
	// ------------------- STATES -------------------
	const [modalActive, setModalActive] = useState(props.active);

	useEffect(() => {
		setModalActive(props.active);
	}, [props.active]);

	return (
		<div className={modalActive ? "modal is-active" : "modal"}>
			<div className="modal-background"></div>
			<div className={`modal-content ${props?.wideModal ? "wide-modal" : ""}`} style={{width: props?.modelWidth}}>
				<div className="card abun-modal-card">
					{props.closeable ?
						<button className={"abun-modal-close"} onClick={() => {
							props.hideModal();
						}}>
							<Icon additionalClasses={[`${props?.wideModal ? "icon-white" : ""}`]} iconName={"delete-circle"}/>
						</button> :
						<></>}
					<div className={props.headerText !== "" ? "card-header" : "card-header is-hidden"}>
						<div className="card-header-title is-justify-content-center">
							<p>{props.headerText}</p>
						</div>
					</div>
					<div className="card-content">
						<div className="content">
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
